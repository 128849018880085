import React from 'react';

import { TagPopover } from '../../../components/tag-popover';
import { VscJson } from 'react-icons/vsc';

const TransformerHelp = ({ editorType }) => {

  return (
    <div>
      <p>
        Change form properties at runtime based on the user input with JavaScript instructions.
        {editorType === 'transformerOnRender' && (
          <span>
            The code is executed every time the form is rendered or re-rendered.
          </span>
        )}
        {editorType === 'transformerOnChange' && (
          <span>
            The code is executed at first render and everytime the value of the field changes.
          </span>
        )}
      </p>
      <p>
        These functions are available<br/><br/>
        <div className="parameter">
          <TagPopover
            title="setParam(fieldName, key, value)"
            text={
              <div>
                Change a value of a specific field of the form (referenced by <em>name</em> property).
                <code>
                  setParam('my_field', 'placeholder', 'Insert name');
                </code>
                Explore the available properties for each field by clicking in the icon
                {' '}
                <VscJson />
                {' '}
                in the top right corner of the field tab.
              </div>}
          >setParam()</TagPopover>
          <TagPopover
            title="arraySetValue(arrayFieldName, fieldName, key, value)"
            text={
              <div>
                Change a value of a specific field of an array in the form (referenced by <em>name</em> properties).
                <code>
                arraySetValue('my_array', 'my_field_in_the_array', 'placeholder', 'Insert name');
                </code>
                Explore the available properties for each field by clicking in the icon
                {' '}
                <VscJson />
                {' '}
                in the top right corner of the field tab.
              </div>}
          >arraySetValue()</TagPopover>
          <TagPopover
            title="element(fieldName)"
            text={
              <div>
                Returns the DOM element which contains a field in the form (referenced by <em>name</em> property).
                <code>
                  element('my_field').style.marginTop = '20px';
                </code>
              </div>}
          >element()</TagPopover>
          <TagPopover
            title="style(fieldName, cssProp, value) | style(fieldName, cssProps)"
            text={
              <div>
                Apply CSS properties to the element which contains a field in the form (referenced by <em>name</em> property).
                <code>
                  style('my_field', 'marginTop','20px');
                </code>
                or using an object as secondo argument
                <code>
                  style('my_field', {'{'}<br/>
                  &nbsp;&nbsp;marginTop: '20px',<br/>
                  &nbsp;&nbsp;marginBottom: '20px'<br/>
                  {'}'});
                </code>
              </div>}
          >style()</TagPopover>
          <TagPopover
            title="css(selector, cssProps)"
            text={
              <div>
                Apply CSS properties to the elements identified by the selector (i.e. <em>".group div"</em>) inside the
                DOM element which contains a field in the form.
                <code>
                  css('.hint p', {'{'} marginTop: '20px' {'}'});
                </code>
              </div>}
          >css()</TagPopover>
        </div>

        <div className="parameter">
          <TagPopover
            title="enable(fieldName)"
            text={
              <div>
                Enable a field in the form (referenced by <em>name</em> property).
                <code>
                  enable('my_field');
                </code>
              </div>}
          >enable()</TagPopover>
          <TagPopover
            title="disable(fieldName)"
            text={
              <div>
                Disable a field in the form (referenced by <em>name</em> property).
                <code>
                  disable('my_field');
                </code>
              </div>}
          >disable()</TagPopover>
          <TagPopover
            title="hide(fieldName)"
            text={
              <div>
                Hide a field in the form (referenced by <em>name</em> property).
                <code>
                  hide('my_field');
                </code>
              </div>}
          >hide()</TagPopover>
          <TagPopover
            title="setFieldValue(fieldName, value)"
            text={
              <div>
                Programmatically set the value of a field (referenced by <em>name</em> property). Causes re-render
                of the field.
                <code>
                  setFieldValue('my_field', 'a_new_value');
                </code>
              </div>}
          >setFieldValue()</TagPopover>
          <TagPopover
            title="show(fieldName)"
            text={
              <div>
                Show a field in the form (referenced by <em>name</em> property).
                <code>
                  show('my_field');
                </code>
              </div>}
          >show()</TagPopover>
        </div>
        <div className="parameter">
          <TagPopover
            title="toggle(fieldName, key)"
            text={
              <div>
                Toggle a boolean flag parameter of a field (referenced by <em>name</em> property).
                <code>
                  toggle('my_field', 'disabled');
                </code>
              </div>}
          >toggle()</TagPopover>
        </div>
        <div className="parameters">
          <TagPopover
            title="<my_field1>...<my_fieldN>"
            text={
              <div>
                The current value of a field is available (as read-only objects), for example if <em>my_field</em> is a <b>checkbox</b> field
                <code>
                  if (my_field) {'{'}<br/>
                  &nbsp;&nbsp;enable('my_other_field')<br/>
                  {'}'}
                </code>
                or if <em>my_field</em> is a <b>input-text</b> field
                <code>
                  if (my_field !== '') {'{'}<br/>
                  &nbsp;&nbsp;show('my_other_field')<br/>
                  {'}'}
                </code>
              </div>}
          >&lt;my_field&gt;</TagPopover>
          <TagPopover
            title="values"
            text={
              <div>
                A read-only hash with all current values of the form. For example if <em>my_field</em> is a <b>checkbox</b> field
                <code>
                  if (values.my_field) {'{'}<br/>
                  &nbsp;&nbsp;enable('my_other_field')<br/>
                  {'}'}
                </code>
                or if <em>my_field</em> is a <b>input-text</b> field
                <code>
                  if (values.my_field !== '') {'{'}<br/>
                  &nbsp;&nbsp;show('my_other_field')<br/>
                  {'}'}
                </code>
              </div>}
          >values</TagPopover>
        </div>
        <div className="tip">
          Explore the available properties for each field by clicking in the icon
          {' '}
          <VscJson />
          {' '}
          in the top right corner of the field tab.
        </div>
      </p>
    </div>
  );
};

export { TransformerHelp };
