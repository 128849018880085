import React, { useCallback } from 'react';

import { useModal } from '../../../components/modal';
import { addField } from '../../../pages/builder/helpers';
import { createEmptyField } from '../../../pages/builder/helpers';
import { useFormBuilderStore } from '../../../pages/builder/state';
import { useEvent, useListenTo } from '../../../code-plug';
import { useNotification, useTracking } from '../../../hooks';
import { fetchTemplate } from '../../../helpers';
import Manifests from '../../../manifest';

import { SelectField } from './select-field';
import { AddFieldButton } from './add-field-button';

const AddFieldButtonLarge = ({
  hideUnavailableComponents = false
}) => {
  const framework = useFormBuilderStore(state => state.framework);
  const setForm = useFormBuilderStore(state => state.setForm);
  const setField = useFormBuilderStore(state => state.setField);
  const form = useFormBuilderStore(state => state.form);
  const setSidebar = useFormBuilderStore(state => state.setSidebar);
  const callInstallTemplate = useEvent('installTemplate');
  const notification = useNotification();
  const tracking = useTracking();

  const { open, close } = useModal({
    view: SelectField,
    size: 'md',
    name: 'selectField',
    align: 'center',
    labelSubmit: 'Close',
    labelCancel: null
  });

  const onAddField = useCallback(
    async (field, target, subtarget, context) => {

      let disabledComponents;
      // disable some kind of components (i.e., not possilbe array inside array)
      if (context === 'array') {
        disabledComponents = ['array'];
      } else if (context === 'group') {
        disabledComponents = ['group'];
      } else if (context === 'two-columns') {
        disabledComponents = ['two-columns', 'three-columns', 'columns'];
      } else if (context === 'three-columns') {
        disabledComponents = ['two-columns', 'three-columns', 'columns'];
      } else if (context === 'columns') {
        disabledComponents = ['columns'];
      }
      const component = await open(null, { disabledComponents, framework, hideUnavailableComponents });
      close();
      if (component) {
        tracking.sendEvent('field.add', { name: component.name });
        // if component is a template, use template procedure
        if (component.template) {
          try {
            const template = await fetchTemplate(component.templateId);
            callInstallTemplate({
              template,
              fieldId: field != null ? field.id : null,
              target,
              subtarget
            });
          } catch(e) {
            notification.error(
              'Add field',
              <div><b>Something went wrong</b>, please try again later</div>
            );
          }
        } else {
          const newField = createEmptyField(Manifests, form.fields, component.name, framework);
          setForm(
            addField(
              form,
              newField,
              field != null ? field.id : null,
              target,
              subtarget
            )
          );
          setField(newField);
          setSidebar('field');
        }
      }
    },
    [callInstallTemplate, hideUnavailableComponents, form, open, framework, close, /*openLogin, closeLogin,*/ tracking, notification, setForm, setField, setSidebar]
  );


  const publishEventAttrs = useListenTo({
    'addField': onAddField
  });

  return (
    <span {...publishEventAttrs}>
      <AddFieldButton
        onClick={onAddField}
        large
      />
    </span>
  );
};

export { AddFieldButtonLarge };
