import _ from 'lodash';

import { fillIds } from '../../../pages/builder/helpers';
import { addField } from '../../../pages/builder/helpers';

/**
 * Merge two templates, settings of the new form will overwrite the old one
 * Also ensure all fields have an id
 *
 * @param {*} currentForm
 * @param {*} templateForm
 * @param {*} id Id of the component to add the field to
 * @param {*} target the fields key of the components: "fields", "tabs", "leftFields", "rightFields", ...
 * @param {*} subtarget the index of the target in case it's an array
 * @returns
 */
const mergeTemplate = (
  currentForm,
  templateForm,
  id,
  target,
  subtarget
) => {

  // if both templates have a render transformer, then glue them
  let mergedTransformer = currentForm.script || currentForm.transformer || templateForm.script || templateForm.transformer;
  if (!_.isEmpty(currentForm.script || currentForm.transformer) && !_.isEmpty(templateForm.script || templateForm.transformer)) {
    mergedTransformer = (currentForm.script || currentForm.transformer) +
      '\n\n' +
      '// JS sdript imported with template\n\n' +
      (templateForm.script || templateForm.transformer)
  };

  // join locales of the two forms
  const mergedLocales = _.union(currentForm.locales, templateForm.locales);

  // add fields to the current form
  const newForm = addField(
    currentForm,
    templateForm.fields,
    id,
    target,
    subtarget
  );

  // rewrite forms, adding new locales, transformers and fill ids
  return {
    ...newForm,
    locales: mergedLocales,
    script: mergedTransformer,
    fields: fillIds(newForm.fields)
  };
};

export { mergeTemplate };
