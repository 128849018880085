import React from 'react';
import _ from 'lodash';

import { ArrowDash } from '../../../assets/icons';
import { useFormBuilderStore } from '../../../pages/builder/state';
import { getEditUsageCount } from '../helpers/edit-usage';

import './start-here.scss';

const StartHereArrow = () => {
  const form = useFormBuilderStore(state => state.form);
  const hasEdited = useFormBuilderStore(state => state.hasEdited);
  const editUsageCount = getEditUsageCount();

  return (
    <div className="start-here">
      {!_.isEmpty(form.fields) && editUsageCount < 3 && !hasEdited && (
        <>
          <ArrowDash flip={true}/>
          <div className="hint">
            Hover the field with the mouse and reveal the <em>"Edit"</em> button
            and edit the field parameters
          </div>
        </>
      )}
    </div>
  );
};

export { StartHereArrow };
