import React, { useCallback, useState } from 'react';
import { Navbar, Nav, Avatar, Whisper, Tooltip, Button } from 'rsuite';
import { Link, useNavigate } from 'react-router-dom';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';

import { IconUser, IconGitHub, IconSlack } from '../../assets/icons';
import { LogoExtended } from '../../assets/logo-large';
import { useCurrentUser } from '../../hooks/current-user';
import { getMainSite, isValidDate } from '../../helpers';
import { useNotification } from '../../hooks';
import { useModal, SignInUpBannerForm } from '../../components';

import { Changelog } from './views/changelog';
import { NewsDetail } from './views/news-detail';

import './navigation.scss';

const todayBeginOfMonth = () => {
  const d = new Date();
  d.setDate(1);
  return d;
}

const GET_NEWS = gql`
query($from: String) {
  news(
    sort: "-date_created",
    limit: 1,
    filter: {
      status: {
        _eq: "published"
      },
      date_created: {
        _gt: $from
      }
    }
  ) {
    id,
    date_created,
    title,
    body,
    status
  }
}`;

const NavLink = React.forwardRef(({ href, children }, ref) => {
  return (
    <Link to={href} ref={ref} className="rs-navbar-item">
      {children}
    </Link>
  );
});

const LoginWrapper = ({ onSubmit, value }) => {
  const handleLogin = useCallback(
    () => onSubmit({ logged: true }),
    [onSubmit]
  );

  return (
    <div>
      <SignInUpBannerForm
        initialMode={value.initialMode}
        onLogin={handleLogin}
      />
    </div>
  );
};

const NavigationBar = ({ onSelect, activeKey, ...props }) => {
  const { user, isAuthenticated, isRegistered, clearCredentials } = useCurrentUser();
  const navigate = useNavigate();
  const notification = useNotification();
  const [visibleNews, setVisibleNews] = useState();
  const [filterDate] = useState(
    localStorage.getItem('lf_last_dismissed') && isValidDate(new Date(localStorage.getItem('lf_last_dismissed'))) ?
      localStorage.getItem('lf_last_dismissed') : todayBeginOfMonth().toISOString()
  );

  useQuery(GET_NEWS, {
    variables: {
      from: filterDate
    },
    onCompleted: data => {
      if (!_.isEmpty(data?.news)) {
        setVisibleNews(data.news[0]);
      }
    }
  });

  const handleDismiss = useCallback(
    () => {
      localStorage.setItem('lf_last_dismissed', new Date().toISOString());
      setVisibleNews(null);
    },
    []
  );

  const { open: openLogin, close: closeLogin } = useModal({
    view: LoginWrapper,
    size: 'xmd',
    name: 'loginModal',
    align: 'center',
    labelSubmit: null,
    labelCancel: null
  });

  const { open: openChangelog, close: closeChangelog } = useModal({
    view: Changelog,
    size: 'md',
    name: 'changelogModal',
    align: 'center',
    className: 'lf-changelog-modal',
    labelSubmit: null,
    labelCancel: null
  });

  const { open: openLastNews, close: closeLastNews } = useModal({
    view: NewsDetail,
    size: 'md',
    name: 'changelogModal',
    align: 'center',
    className: 'lf-last-news-modal',
    labelSubmit: null,
    labelCancel: null
  });

  const handleOpenNews = useCallback(
    async () => {
      await openLastNews(visibleNews);
      closeLastNews();
    },
    [closeLastNews, openLastNews, visibleNews]
  );

  const handleSelect = useCallback(
    async eventKey => {
      if (eventKey === 'cmd:logout') {
        try {
          await clearCredentials();
          navigate('/login');
        } catch(e) {
          notification.somethingWentWrong();
        }
      } else if (eventKey === 'cmd:settings') {
        navigate('/settings');
      } else if (eventKey === 'cmd:slack') {
        window.location = process.env.REACT_APP_SLACK_INVITE;
      } else if (eventKey === 'cmd:settings:membership') {
        navigate('/settings/plan');
      } else if (eventKey === 'cmd:settings:user') {
        navigate('/settings/user');
      } else if (eventKey === 'cmd:changelog') {
        await openChangelog();
        closeChangelog();
      }
    },
    [clearCredentials, closeChangelog, navigate, notification, openChangelog]
  );

  const handleLoginSignup = useCallback(
    async () => {
      const res = await openLogin({ initialMode: isRegistered ? 'signIn' : 'signUp' });
      closeLogin();
      if (res) {
        notification.success(
          'Success',
          <div>You signed in succesfully!</div>
        );
      }
    },
    [closeLogin, isRegistered, openLogin, notification]
  );

  const avatar = user && user.avatar ?
    <Avatar size="md" circle src={user.avatar} alt={user.name || ''}/> :
    <Avatar size="md" circle><IconUser /></Avatar>;

  return (
      <Navbar className="navigation" {...props}>
        <Navbar.Brand href="/" data-testid="lf-logo">
          <LogoExtended width={150} height={45} style={{ marginTop: '-13px', marginLeft: '-14px' }} />
        </Navbar.Brand>
        <Nav>
          {isAuthenticated && (
            <Nav.Item
              as={NavLink}
              href="/dashboard"
            >{user?.organization?.name ? user.organization.name + ' ' : null}Dashboard</Nav.Item>
          )}
          {visibleNews && (
            <div className="lf-news-bar">
              <div
                className="inner"
                onClick={handleOpenNews}
              >{visibleNews.title}</div>
              <Button appearance="ghost" size="xs" onClick={handleDismiss}>Dismiss</Button>
            </div>
          )}
        </Nav>
        <Nav pullRight
          appearance="subtle"
          className="right-menu"
        >
          <Button
            appearance="default"
            className="btn-help"
            startIcon={<HelpOutlineIcon />}
            href={getMainSite() + '/lets-form-designer/getting-started'}
            target="_blank"
          >Help</Button>
          {!isAuthenticated && (
            <Button
              appearance="primary"
              className="btn-login-signup"
              onClick={handleLoginSignup}
            >
              {isRegistered ? 'Login' : 'Sign up'}
            </Button>
          )}
          <Whisper
            placement="bottomEnd"
            trigger="hover"
            speaker={<Tooltip arrow={false}>The LetsForm repository on GitHub</Tooltip>}
          >
            <Nav.Item
              href="https://github.com/guidone/lets-form"
              target="_blank"
              icon={<IconGitHub width={24} height={24}/>}
            />
          </Whisper>
          <Whisper
            placement="bottomEnd"
            trigger="hover"
            speaker={<Tooltip arrow={false}>The LetsForm Slack space</Tooltip>}
          >
            <Nav.Item
              href={process.env.REACT_APP_SLACK_INVITE}
              target="_blank"
              icon={<IconSlack width={24} height={24}/>}
            />
          </Whisper>
          {isAuthenticated && (
            <Nav.Menu
              noCaret
              placement="bottomEnd"
              icon={avatar}
            >
              <Nav.Item eventKey="cmd:settings:user" onSelect={handleSelect}>
                <b>{user.full_name}</b>
              </Nav.Item>
              <Nav.Item eventKey="cmd:settings:membership" onSelect={handleSelect}>
                Current plan: <b>{user ? user.plan.name : 'Free'}</b>
              </Nav.Item>
              <Nav.Item eventKey="cmd:settings" onSelect={handleSelect}>Settings</Nav.Item>
              <Nav.Item eventKey="cmd:logout" onSelect={handleSelect}>Logout</Nav.Item>
              <Nav.Item eventKey="cmd:changelog" onSelect={handleSelect}>Changelog</Nav.Item>
              <Nav.Item eventKey="cmd:slack" onSelect={handleSelect}>Slack channel</Nav.Item>
              <Nav.Item className="item_version">
                <span>Version: {process.env.REACT_APP_VERSION}</span>
              </Nav.Item>
            </Nav.Menu>
          )}
        </Nav>
      </Navbar>
  );
};

export { NavigationBar }