import { filterFields } from '../../../components/lets-form/index';

const deleteField = (form, field) => {
  return {
    ...form,
    fields: filterFields(
      form.fields,
      currentField => currentField.id !== field.id
    )
  };
};

export { deleteField };
