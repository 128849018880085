import React, { useCallback } from 'react';
import { Dropdown } from 'rsuite';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { useModal } from '../../../components/modal';
import { useTracking } from '../../../hooks';
import { validateJSONForm } from '../../../components/lets-form';
import { fillIds } from '../../../pages/builder/helpers';

import { ImportForm } from './import-form';

const ImportFormMenuItem = () => {
  const tracking = useTracking();
  const setFormValue = useFormBuilderStore(state => state.setFormValue);
  const setDefaultValues = useFormBuilderStore(state => state.setDefaultValues);
  const setForm = useFormBuilderStore(state => state.setForm);
  const setSidebar = useFormBuilderStore(state => state.setSidebar);
  const setEditMode = useFormBuilderStore(state => state.setEditMode);
  const setField = useFormBuilderStore(state => state.setField);
  const setGeneration = useFormBuilderStore(state => state.setGeneration);

  const { open: openImportCode, close: closeImportCode } = useModal({
    view: ImportForm,
    size: 'lg',
    name: 'import_form',
    align: 'center',
    labelSubmit: 'Import form',
    labelCancel: 'Cancel',
    title: 'Import Form',
    enableSubmit: value => {
      return validateJSONForm(value) == null;
    }
  });

  const handleImport = useCallback(
    async () => {
      tracking.sendEvent('form.import-from-code');
      const importedForm = await openImportCode();
      await closeImportCode();
      if (importedForm) {
        const newForm = {
          ...importedForm,
          fields: fillIds(importedForm.fields)
        };
        setFormValue({});
        setDefaultValues(undefined);
        setForm(newForm);
        setField(null);
        setSidebar('form');
        setEditMode(true);
        setGeneration(generation => generation + 1);
      }
    },
    [tracking, openImportCode, closeImportCode, setFormValue, setDefaultValues, setForm, setField, setSidebar, setEditMode, setGeneration]
  );



  return (
    <Dropdown.Item onSelect={handleImport}>Import form</Dropdown.Item>
  );
};

export { ImportFormMenuItem };
