import _ from 'lodash';

import {
  FRAMEWORKS,
  FRAMEWORKS_LABELS,
  mapFields
} from '../../../components/lets-form';
import { COMMON_HTML5, COMMON_VALIDATION, COMMON_CLASS } from '../../../manifest/lets-form-manifests/costants';

import { OmniEditorField } from '../../../components/omni-editor';


const COMMON_JS_SNIPPET = {
  name: 'script',
  component: 'react-view',
  editorType: 'transformerOnChange',
  view: OmniEditorField
};

const labelsFramework = FRAMEWORKS
  .reduce((acc, framework, idx) => ({ ...acc, [framework]: FRAMEWORKS_LABELS[idx] }), {});

// add the button for custom js validation in the foldable group
// COMMON_VALIDATION is used to the mappings (the list of form properties that can be changed vith
// a transformer), it's ok to exclude this, means a transformer cannot change a js validator
const COMMON_VALIDATION_WITH_JS = [
  {
    ...COMMON_VALIDATION[0],
    fields: [
      ...COMMON_VALIDATION[0].fields,
      {
        name: 'validationValidate',
        component: 'react-view',
        view: OmniEditorField
      }
    ]
  }
];

const findFieldByName = (fields, name) => (fields || []).find(field => field.name === name);

const generateFormForField = (/*field,*/manifest, framework, locales, locale, name) => {
  const hasValidation = !(manifest['form-generator'] && manifest['form-generator'].hideValidation);
  const hasJsSnippet = !(manifest['form-generator'] && manifest['form-generator'].hideJsSnippet);
  const hasHtml5 = manifest['form-generator'] && manifest['form-generator'].html5 === true;

  let frameworkFields = [];
  if (manifest != null && _.isArray(manifest[framework]) && !_.isEmpty(manifest[framework])) {
    frameworkFields = manifest[framework];
  }

  let commonFields = [];
  let commonFieldsNames = [];
  if (manifest != null && Array.isArray(manifest.fields)) {
    commonFields = manifest.fields
      .map(field => {
        // if a field with the same name it's also present in framework field
        // use this, it's a way to override general settings with framework-specific
        // one
        return findFieldByName(frameworkFields, field.name) || field;
      });
      // collect all field names of common fields (thoso available for all framework)
      // it will be used to filter out framework specific fields, are to be considered
      // as overridding fields
      // only do that at first level
      commonFieldsNames = manifest.fields.reduce((acc, field) => [...acc, field.name], []);
  }



  let form = {
    version: 2,
    layout: 'vertical',
    name,
    locales,
    fluid: true,
    script: manifest.script || manifest.transformer,
    fields: [
      // component specific fields
      ...commonFields,
      // platform specific
      ...(frameworkFields && frameworkFields.length !== 0 ?
        [{
          id: 'platform-specific',
          label: `${labelsFramework[framework]} params`,
          component: 'group',
          name: 'platform-specific-' + framework,
          fields: frameworkFields.filter(field => !commonFieldsNames.includes(field.name))
        }] : []),
      ...(hasJsSnippet ? [COMMON_JS_SNIPPET] : []),
      ...COMMON_CLASS,
      ...(hasHtml5 ? [COMMON_HTML5] : []),
      ...(hasValidation ? COMMON_VALIDATION_WITH_JS : [])
    ]
  };

  // if locales and locale, then for all i18n fields, set the default language to locale
  if (locale && _.isArray(locales) && !_.isEmpty(locales)) {
    form = {
      ...form,
      fields: mapFields(
        form.fields,
        field => {
          if (field.component === 'input-text-i18n') {
            return { ...field, defaultLocale: locale };
          }
          return field;
        }
      )
    }
  }

  return form;
};

export { generateFormForField };
