import React, { useState, useEffect, useCallback } from 'react';
import { Container, Content, Button, ButtonToolbar } from 'rsuite';
import _ from 'lodash';

import { useLocalStorage } from '../../../hooks/local-storage';
import { validateJSONForm } from '../../../components/lets-form';
import { fillIds } from '../../builder/helpers';

import './import-form.scss';

function fromBinary(encoded) {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

const ImportForm = ({
  form,
  onImported = () => {}
}) => {
  const [persistedForm, setPersistedForm] = useLocalStorage('form', '');
  const [formToBeImported, setFormToBeImported] = useState(undefined);
  const [modal, setModal] = useState(null);
  const [importing, setImporting] = useState(false);

  useEffect(
    () => {
      let formFromUrl
      // try to import it
      try {
        formFromUrl = JSON.parse(fromBinary(form));
      } catch(e) {
        setFormToBeImported(null);
        return;
      }
      // check if is valid form
      if (validateJSONForm(formFromUrl)) {
        console.error('[LetsForm] The JSON schema of the form is not valid: ', validateJSONForm(formFromUrl));
        setFormToBeImported(null);
        return;
      }
      // finally set to import
      setFormToBeImported(formFromUrl);
    },
    [form]
  );

  useEffect(
    () => {
      if (formToBeImported) {
        if (!_.isEmpty(_.trim(persistedForm))) {
          setModal('modalOverwrtie');
        } else {
          // wait a little then import filling the ids
          setTimeout(() => {
            setPersistedForm({
              ...formToBeImported,
              fields: fillIds(formToBeImported.fields)
             });
            // tell parent it was imported
            onImported(true);
          }, 800);
        }
      }
      // if null or undefined, nothing to do
    },
    [formToBeImported, onImported, setPersistedForm, persistedForm]
  );

  const handleKeepIt = useCallback(
    () => {
      // replace query string without reloading
      onImported(false);
    },
    [onImported]
  );

  const handleOverwriteIt = useCallback(
    () => {
      setImporting(true);
      // voide the persistend form
      setPersistedForm(null);
      // refresh the form to be imported to trigger again the useEffect above
      setFormToBeImported(form => ({ ...form }));
    },
    [setPersistedForm]
  );

  return (
    <Container className="pages-import-form">
      <Content className="importer">
        {formToBeImported === null && (
          <div>
            <h3>Error importing form</h3>
            <div className="action">
              The form you're trying to import is not valid
              <ButtonToolbar>
                <Button
                  size="lg"
                  disabled={importing}
                  appearance="primary"
                  onClick={handleKeepIt}
                >Ok, go back to the designer</Button>
              </ButtonToolbar>
            </div>
          </div>
        )}

        {(formToBeImported === undefined || formToBeImported ) && (
          <>
            {!modal && (
              <div>
                <h3>Importing form...</h3>
                {formToBeImported && (
                  <div className="action">
                  The form "{!_.isEmpty(formToBeImported.name) ? formToBeImported.name : 'unnamed'}" is about to imported in <b>LetsForm Designer</b>
                </div>
                )}
              </div>
            )}
            {modal === 'modalOverwrtie' && (
              <>
                <h3>Importing form "{!_.isEmpty(formToBeImported.name) ? formToBeImported.name : 'unnamed'}"</h3>
                <div className="action">
                  You are currently editing a form in <b>LetsForm Designer</b>, do you want to overwrite it?
                  <ButtonToolbar>
                    <Button
                      disabled={importing}
                      size="lg"
                      appearance="primary"
                      onClick={handleOverwriteIt}
                    >Yes, overwrite it</Button>
                    <Button
                      size="lg"
                      disabled={importing}
                      appearance="ghost"
                      onClick={handleKeepIt}
                    >No, keep it</Button>
                  </ButtonToolbar>
                </div>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
}

export { ImportForm };
