import {
  COMMON_HIDDEN,
  COMMON_NAME,
  COMMON_ALIGN_SELF
} from '../costants/index.js';

const manifest = {
  label: 'Columns',
  category: 'layout',
  name: 'columns',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    {
      component: 'array',
      name: 'columns',
      label: 'Select columns',
      maxHeight: 350,
      fields: [
        {
          component: 'columns',
          name: 'array-option-item',
          columns: [
            { name: 'col1', size: 2 },
            { name: 'col2', size: 2 },
            { name: 'col3', size: 1 },
            { name: 'col4', size: 0 }
          ],
          fields: {
            col1: [
              {
                name: 'name',
                component: 'input-text',
                placeholder: 'value',
                size: 'sm',
                fullWidth: true
              }
            ],
            col2: [
              {
                ...COMMON_ALIGN_SELF,
                name: 'alignment',
                placeholder: 'align',
                label: undefined,
                size: 'sm'
              },
            ],
            col3: [
              {
                name: 'size',
                component: 'input-number',
                placeholder: 'size',
                size: 'sm',
                min: 0,
                step: 1,
                max: 12
              }
            ],
            col4: [
              {
                component: 'button',
                label: 'Field 6',
                name: 'hidden',
                size: 'sm',
                buttonType: 'toggle',
                iconOff: 'https://unpkg.com/lets-form-icons@1.0.3/eye.svg',
                iconOn: 'https://unpkg.com/lets-form-icons@1.0.0/eye-off.svg',
                initialValue: false,
                hint: 'Show / hide',
                'react-rsuite5': {
                  tooltip: true
                }
              }
            ]
          }
        }
      ]
    },
    COMMON_HIDDEN
  ],
  'react-rsuite5': [

  ],
  'form-generator': {
    hideValidation: true,
    hasLabel: false
  },
  defaultValues: {
    'react-rsuite5': {
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ]
    },
    'react': {
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ]
    },
    'react-bootstrap': {
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ]
    },
    'react-material-ui': {
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ]
    },
    'react-antd': {
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ]
    }
  }
};

export { manifest };
