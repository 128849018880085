import _ from 'lodash';

import { mapFields } from '../../../components/lets-form';
import { makeColums } from '../../../manifest/lets-form-manifests/costants';
import { FRAMEWORKS, FRAMEWORKS_LABELS } from '../../../components/lets-form';
import { OmniEditorField } from '../../../components/omni-editor';

import { MultiselectLanguage } from '../../../components/multiselect-language';

const labelsFramework = FRAMEWORKS
  .reduce((acc, framework, idx) => ({ ...acc, [framework]: FRAMEWORKS_LABELS[idx] }), {});

const createFormForm = ({
  framework,
  manifest,
  locales,
  locale
}) => {

  const fields = [
    {
      name: 'name',
      component: 'input-text',
      label: 'Form name',
      hint: 'Only for reference (i.e., during debug), doesn\'t affect JSON payload',
      'react-rsuite5': {
        tooltip: true
      }
    },
    {
      name: 'locales',
      component: 'react-view',
      label: 'Locales',
      hint: 'Select locales supported by the form',
      view: MultiselectLanguage,
      'react-rsuite5': {
        tooltip: true
      }
    },
    {
      name: 'fields-status',
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 },
        { name: 'three', size: 1 }
      ],
      fields: {
        one: [
          {
            name: 'disabled',
            component: 'toggle',
            label: 'Disabled'
          }
        ],
        two: [
          {
            name: 'readOnly',
            component: 'toggle',
            label: 'Read only'
          }
        ],
        three: [
          {
            name: 'plaintext',
            component: 'toggle',
            label: 'Plaintext'
          }
        ]
      }
    },
    {
      name: 'submit-group',
      label: 'Form labels',
      component: 'group',
      collapsible: true,
      open: true,
      fields: [
        {
          name: 'labelSubmit',
          component: 'input-text-i18n',
          label: 'Submit label',
          hint: 'Label for submit button',
          'react-rsuite5': {
            tooltip: true
          }
        },
        {
          name: 'labelCancel',
          component: 'input-text-i18n',
          label: 'Cancel label',
          hint: 'Label for submit button',
          'react-rsuite5': {
            tooltip: true
          }
        },
        makeColums(
          {
            name: 'buttonsAlign',
            label: 'Buttons alignment',
            component: 'select',
            cleanable: false,
            searchable: false,
            options: [
              { value: 'left', label: 'Left' },
              { value: 'center', label: 'Center' },
              { value: 'right', label: 'Right' }
            ]
          },
          []
        )
      ]
    },
    _.isArray(manifest.fields) && !_.isEmpty(manifest.fields) ?
      {
        name: 'framework-group',
        label: `${labelsFramework[framework]} Params`,
        component: 'group',
        collapsible: true,
        open: true,
        fields: manifest.fields
      } : null,
    {
      name: 'validation-group',
      label: 'Form validation',
      component: 'group',
      collapsible: true,
      open: true,
      fields: [
        makeColums(
          {
            name: 'validationMode',
            component: 'select',
            label: 'Validation mode',
            hint: 'When validation is triggered (may impact performance)',
            options: [
              { value: undefined, label: 'none' },
              { value: 'all', label: 'all' },
              { value: 'onBlur', label: 'onBlur' },
              { value: 'onChange', label: 'onChange' },
              { value: 'onSubmit', label: 'onSubmit' },
            ],
            'react-rsuite5': {
              tooltip: true
            }
          },
          {
            name: 'showErrors',
            component: 'select',
            label: 'Show errors',
            hint: 'How to show validation errors',
            options: [
              { value: 'none', label: 'None' },
              { value: 'inline', label: 'Inline' },
              { value: 'groupedTop', label: 'Grouped on top' },
              { value: 'groupedBottom', label: 'Grouped at bottom' }
            ],
            'react-rsuite5': {
              tooltip: true
            }
          }
        ),
        {
          name: 'disableSubmitOnError',
          component: 'checkbox',
          label: 'Disable submit on errors'
        }
      ]
    },
    {
      name: 'css',
      component: 'react-view',
      editorType: 'css',
      view: OmniEditorField
    },
    {
      name: 'script',
      component: 'react-view',
      editorType: 'transformerOnChange',
      view: OmniEditorField
    }
  ];

  let formForm = {
    version: 2,
    layout: 'vertical',
    locales,
    fluid: true,
    fields: fields.filter(Boolean)
  };

    // if locales and locale, then for all i18n fields, set the default language to locale
    if (locale && _.isArray(locales) && !_.isEmpty(locales)) {
      formForm = {
        ...formForm,
        fields: mapFields(
          formForm.fields,
          field => {
            if (field.component === 'input-text-i18n') {
              return { ...field, defaultLocale: locale };
            }
            return field;
          }
        )
      }
    }

  return formForm;
};

export { createFormForm };
