/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { FlexboxGrid, Col, Nav } from 'rsuite';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { useFormBuilderStore, FormBuilderProvider, useRawFormBuilderStore } from './state';

import FormGenerator from '../../components/lets-form/index';
import {
  EmptyPlaceholder,
  ErrorBoundary,
  EnsureFrameworkProvider
} from '../../components';
import { defaultForm, fetchTemplate } from '../../helpers';
import { useTracking } from '../../hooks';
import { useMapRegion, useRegion, useRawRegion, useEvent } from '../../code-plug';

import {
  moveAfterField,
  getValidFramework,
  getValidSize,
  getValidLocale,
  moveBeforeField,
  createEmptyField,
  deleteField,
  addField,
  AddFieldAfter
} from './helpers';
import {
  EditWrapper,
  PlaceholderWrapper,
  GroupWrapper,
  ShowError
} from './components';

import Manifests from '../../manifest';

import './index.scss';
import './lf-form-editor-fixes.scss';
import './lf-form-framework-resets.scss';

const RawBuilder = ({
  initialEditMode = true,
  formSchema,
  formFramework,
  formLocale,
  formSize = 12,
  features = {
    shareForm: true,
    showProBadge: false,
    badgeContent: 'Pro',
    cancelButton: true,
    saveOptions: true,
    playgroundForm: false,
    connectors: false
  },
  onChange = () => {},
  onChangeLocale = () => {},
  onChangeSize = () => {},
  onChangeFramework = () => {},
  onCancel = () => {},
  onSave = () => {},
  onSelect = () => {}
}) => {
  const [modal, setModal] = useState(null);
  const {
    hasEdited,
    setHasEdited,
    sidebar,
    setSidebar,
    editMode,
    // setEditMode,
    size,
    framework,
    form,
    setForm,
    locale,
    // setLocale,
    generation,
    // setGeneration,
    defaultValues,
    // setDefaultValues,
    plaintextMode,
    // setPlaintextMode,
    field,
    setField,
    //formValue,
    setFormValue,
    //errors,
    setErrors,
    generalError,
    setGeneralError,
    //responses,
    setResponses,
    //jsErrors,
    setJsErrors
  } = useFormBuilderStore();
  const store = useRawFormBuilderStore();
  const tracking = useTracking();

  const emitEditField = useEvent('builderEditField');
  const mapTabs = useMapRegion('builder-tabs');
  const renderTabs = useRegion('builder-tabs');
  const renderCanvas = useRegion('form-builder-canvas');
  const renderPageBuilder = useRegion('form-builder-page');
  const renderFormFooter = useRegion('form-builder-footer');
  const renderPreview = useRegion('form-builder-preview');
  const regionsInnerFooter = useRawRegion('form-builder-inner-footer');
  const renderTopBars = useRegion('form-builder-topbar');

  // listen to changes to framework, propagate up
  useEffect(
    () => store.subscribe(
      state => state.framework,
      framework => {
        onChangeFramework(framework)
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeFramework]
  );

  // listen to changes to size, propagate up
  useEffect(
    () => store.subscribe(
      state => state.size,
      framework => {
        onChangeSize(framework)
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeSize]
  );

  // listen to changes to locale, propagate up
  useEffect(
    () => store.subscribe(
      state => state.locale,
      locale => {
        onChangeLocale(locale)
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeLocale]
  );

  useEffect(
    () => store.subscribe(
      state => state.closed,
      () => {
        onCancel();
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCancel]
  );

  useEffect(
    () => store.subscribe(
      state => state.form,
      newForm => {
        onChange(newForm);
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const handeChange = useCallback(
    (values, errors) => {
      setFormValue(values);
      setErrors({});
    },
    [setErrors, setFormValue]
  );

  const Wrapper = useMemo(
    () => (
      ({ field: currentField, fields, children, level, index } = {}) => {
        return (
          <EditWrapper
            level={level}
            field={currentField}
            fields={form.fields}
            selected={currentField.id === field?.id}
            onEdit={editField => {
              tracking.sendEvent('field.edit');
              emitEditField(editField);
              setHasEdited(true);
              setField(editField);
              setSidebar('field');
            }}
            onDelete={fieldToRemove => {
              tracking.sendEvent('field.delete');
              setForm(deleteField(form, fieldToRemove));
              setSidebar('form');
            }}
            onMove={(dragIndex, hoverIndex) => {
              tracking.sendEvent('field.drag');
              setForm({
                ...form,
                fields: moveAfterField(form.fields, dragIndex, hoverIndex)
              });
            }}
            onAddField={(componentName, fieldId) => {
              const newField = createEmptyField(Manifests, form.fields, componentName, framework);
              setForm(
                AddFieldAfter(
                  form,
                  newField,
                  fieldId
                )
              );
              setField(newField);
              setSidebar('field');
            }}
            onAddTemplate={async (templateId, fieldId) => {
              const template = await fetchTemplate(templateId);
              setForm(
                AddFieldAfter(
                  form,
                  template.jsonTemplate.fields,
                  fieldId
                )
              );
            }}
            index={index}
            framework={framework}
          >
            {children}
          </EditWrapper>
        );
      }
    ),
    [form, field, framework, tracking, emitEditField, setHasEdited, setField, setSidebar, setForm]
  );

  const PlaceholderWrapperView = useMemo(
    () => (
      ({ nextField, parentField, parentFieldTarget, parentFieldSubTarget, fields, children, level, index } = {}) => {
        return (
          <PlaceholderWrapper
            level={level}
            nextField={nextField}
            parentField={parentField}
            parentFieldTarget={parentFieldTarget}
            parentFieldSubTarget={parentFieldSubTarget}
            fields={form.fields}
            onMove={(dragId, hoverId) => {
              tracking.sendEvent('field.drag');
              // Move the field with id dragId before the field with id hoverId
              setForm({
                ...form,
                fields: moveBeforeField(form.fields, dragId, hoverId)
              });
            }}
            onAppend={(field, toField, toTarget, toSubTarget) => {
              tracking.sendEvent('field.drag');
              // remove from it's original position
              let newForm = deleteField(form, field);
              newForm = addField(
                newForm,
                field,
                toField.id,
                toTarget,
                toSubTarget,
                'start'
              );
              setForm(newForm);
            }}
            onAddField={(componentName, toField, toTarget, toSubTarget) => {
              const newField = createEmptyField(Manifests, form.fields, componentName, framework);
              // add the field next to the
              setForm(
                addField(
                  form,
                  newField,
                  toField?.id, // if null means landing zone is empty or top of root
                  toTarget,
                  toSubTarget,
                  'start'
                )
              );
              setField(newField);
              setSidebar('field');
            }}
            onAddTemplate={async (templateId, toField, toTarget, toSubTarget) => {
              const template = await fetchTemplate(templateId);
              setForm(
                addField(
                  form,
                  template.jsonTemplate.fields,
                  toField?.id, // if null means landing zone is empty or top of root
                  toTarget,
                  toSubTarget,
                  'start'
                )
              );
            }}
            index={index}
            framework={framework}
          />
        );
      }
    ),
    [form, framework, tracking, setForm, setField, setSidebar]
  );

  const GroupWrapperView = useMemo(
    () => (
      ({ field: currentField, children, level, index, className, hovering } = {}) => {
        return (
          <GroupWrapper
            level={level}
            field={currentField}
            fields={form.fields}
            hovering={hovering}
            selected={currentField.id === field?.id}
            className={className}
            onEdit={editField => {
              tracking.sendEvent('field.edit');
              !hasEdited && setHasEdited(true);
              emitEditField(editField);
              setField(editField);
              setSidebar('field');
            }}
            onDelete={fieldToRemove => {
              tracking.sendEvent('field.delete');
              setForm(deleteField(form, fieldToRemove));
              setSidebar('form');
            }}
            onMove={(dragIndex, hoverIndex) => {
              tracking.sendEvent('field.drag');
              setForm({
                ...form,
                fields: moveAfterField(form.fields, dragIndex, hoverIndex)
              });
            }}
            onAddField={(componentName, fieldId) => {
              const newField = createEmptyField(Manifests, form.fields, componentName, framework);
              setForm(
                AddFieldAfter(
                  form,
                  newField,
                  fieldId
                )
              );
              setField(newField);
              setSidebar('field');
            }}
            index={index}
            framework={framework}
          >
            {children}
          </GroupWrapper>
        );
      }
    ),
    [form, field, framework, tracking, hasEdited, emitEditField, setHasEdited, setField, setSidebar, setForm]
  );


  const handleSubmit = useCallback(
    values => {
      setResponses(null);
      setErrors({});
      setSidebar('debug');
    },
    [setErrors, setResponses, setSidebar]
  );

  const handleSubmitted = useCallback(
    responses => {
      setResponses(responses);
      setErrors({});
      setSidebar('debug');
    },
    [setErrors, setResponses, setSidebar]
  );

  const handleSubmitError = useCallback(
    response => {
      setResponses(response);
      setErrors({});
      setSidebar('debug');
    },
    [setErrors, setResponses, setSidebar]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {modal === 'showImportError' && (
        <ShowError
          title="Import error"
          onClose={() => {
            setGeneralError(null);
            setModal(null);
          }}
        >
          <b>Was not possible to import the selected template.</b><br/>
          The current form has some field names which already exists in the selected template: <em>{generalError}</em>.<br/>
          Either rename or remove those field to use this template, cannot exist two field with the same name in a form.
        </ShowError>
      )}
      <div className="pages-builder">
        {renderPageBuilder()}
        <div className="form-previewer">
          {renderTopBars()}
          <div className="canvas">
            {renderCanvas()}
            <FlexboxGrid justify="space-around">
              <FlexboxGrid.Item as={Col} colspan={24} md={size} className="previewer">
                {renderPreview()}
                {form && _.isEmpty(form.fields) && !editMode && (
                  <EmptyPlaceholder>
                    There are no fields defined in this form, to add one click on "Edit form" on the top
                    right corner and then "Add field"
                  </EmptyPlaceholder>
                )}
                <ErrorBoundary>
                  <EnsureFrameworkProvider
                    framework={framework}
                    locale={locale}
                    form={form}
                  >
                    <FormGenerator
                      key={`form_${generation}`}
                      debug={true}
                      defaultValues={defaultValues}
                      locale={locale}
                      form={form}
                      framework={framework}
                      plaintext={plaintextMode}
                      wrapper={editMode && !plaintextMode ? Wrapper : undefined}
                      groupWrapper={editMode && !plaintextMode ? GroupWrapperView : undefined}
                      placeholderWrapper={editMode && !plaintextMode ? PlaceholderWrapperView : undefined}
                      bottomView={editMode && !plaintextMode && !_.isEmpty(regionsInnerFooter) ?
                        regionsInnerFooter[0].View : undefined}
                      demo={editMode && !plaintextMode}
                      loader={null} // disable loader, avoid suspance flickering
                      onChange={handeChange}
                      onJavascriptError={e => {
                        setJsErrors(e);
                        setSidebar('debug');
                      }}
                      onError={errors => {
                        setErrors(errors);
                        setSidebar('debug');
                      }}
                      onSubmit={handleSubmit}
                      onSubmitSuccess={handleSubmitted}
                      onSubmitError={handleSubmitError}
                      footer={editMode && (
                        <>
                          {renderFormFooter()}
                        </>
                      )}
                    >
                    </FormGenerator>
                  </EnsureFrameworkProvider>
                </ErrorBoundary>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </div>
        <div className="sidebar">
          <Nav
            onSelect={setSidebar}
            appearance="subtle"
            className="sidebar-navigator"
          >
            {mapTabs(({ params }) => (
              <Nav.Item
                key={params.tabKey}
                eventKey={params.tabKey}
                active={sidebar === params.tabKey}
              >{params.tabName}</Nav.Item>
            ))}
          </Nav>
          <div className="sidebar-container">
            {renderTabs(null, ({ params }) => params.tabKey === sidebar)}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

const Builder = props => {
  const {
    initialSidebar,
    initialEditMode = true,
    formSchema,
    formFramework,
    formLocale,
    formSize = 12,
    formId,
    projectId
  } = props;

  const regionsTabs = useRawRegion('builder-tabs');
  let sidebar = initialSidebar;

  // if no initial sidebar is specified, take the first as default one
  if (!sidebar) {
    if (regionsTabs.length !== 0) {
      sidebar = regionsTabs[0].params.tabKey
    }
  }

  return (
    <FormBuilderProvider
      sidebar={sidebar}
      editMode={initialEditMode}
      formId={formId}
      projectId={projectId}
      size={getValidSize(formSize)}
      framework={getValidFramework(formFramework)}
      form={defaultForm(formSchema)}
      locale={getValidLocale(formLocale)}
    >
      <RawBuilder {...props} />
    </FormBuilderProvider>
  );
};


export { Builder };
