import _ from 'lodash';

import { reduceFields, findField } from '../../../components/lets-form/index';

const fieldExists = (fields, predicate) => findField(fields, predicate) != null;

const createEmptyField = (Manifests, fields, component, framework) => {
  const countFields = reduceFields(
    fields,
    (field, accumulator) => accumulator + 1,
    0
  );

  let newId = _.uniqueId(`field_${countFields + 1}_`);
  let newName = `field_${countFields + 1}`;

  let retries = 0;
  let exists = fieldExists(fields, field => field.name === newName || field.id === newId);

  while(exists && retries < 100) {
    newId = newId + '_1';
    newName = newName + '_1';
    // eslint-disable-next-line no-loop-func
    exists = fieldExists(fields, field => field.name === newName || field.id === newId);
    ++retries;
  }

  let newField = {
    component,
    name: newName,
    id: newId
  }

  // only add label if component has it, i.e. columns hasn't
  if (!Manifests[component]['form-generator'] || Manifests[component]['form-generator'].hasLabel !== false) {
    newField.label = `Field ${countFields + 1}`;
  }

  // if component has default values
  if (Manifests[component] && Manifests[component].defaultValues) {
    // apply all defaults for all platforms
    Object.keys(Manifests[component].defaultValues)
      .forEach(framework => {
        newField = { ...newField, ...Manifests[component].defaultValues[framework] };
      });
    // be sure to apply the current framework as last one (in case it includes non framework specific values)
    if (Manifests[component].defaultValues[framework]) {
      newField = { ...newField, ...Manifests[component].defaultValues[framework] };
    }
  }

  return newField;
};

export { createEmptyField };
